@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 1s ease-out;
}


.line-separator {
  height: 8px;
  background: linear-gradient(to right, transparent, #383e85, transparent);
  position: relative;
}

.line-separator::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: #232753;
  animation: line-grow 2s ease-out infinite;
}

@keyframes line-grow {
  0%, 100% {
    width: 0;
    left: 50%;
  }
  50% {
    width: 100%;
    left: 0;
  }
}


a.relative::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #1a202c; /* Color de la barra */
  transition: width 0.3s ease-in-out;
}

a.relative:hover::after {
  width: 100%;
}
